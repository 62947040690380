html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow-x: hidden;
    font-family: 'Poppins';
}


.banner {
    position: relative;
    overflow: hidden;
    width: 100%;
    z-index: 1000;
}

.banner img {
    width: 100%;
    height: auto;
}

.dropdown{
    margin-top: 0vw;
}

/* styles.css */
.alert {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
    transition: opacity 1s ease-out;
}

.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.alert-hide {
    opacity: 0;
}



.dropdown-menu {
    margin-top: -1vw;
    background-color: rgba(255, 255, 255, 0.8); /* Cambia la transparencia aquí */
    font-size: 1vw;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3;

  }

  .icon-nav-space{
    margin-bottom: -1.5vw;
}

  .dropdown-item:hover, .dropdown-item:focus {
    background-color: #E42620;
    color: #FFFFFF;
    transition: background-color 0.3s, color 0.3s;
}

.dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0; 
    opacity: 1;
    visibility: visible;
}

.text-container {
    position: absolute;
    top: 25%;
    left: 6%;
    z-index: 1;
    color: #FFF;
    display: flex;
    flex-direction: column;
}

.logo {
    font-size: 8vw;
    font-weight: 700;
    margin: 0;
}

.innovations{
    font-size: 1.5vw;
    font-weight: 300;
    color: #fff;
    margin-top: 5%;
    margin-bottom: 1%;
}

.sense{
    font-size: 1.5vw;
    font-weight: 300;
    color: #fff;
    margin-bottom: 1%;
}


.beauty {
    font-size: 1.5vw;
    font-weight: 300;
    color: #fff;
}

.subtext {
    font-size: 4.3vw;
    margin-top: -5%;
    font-weight: 700;
    margin-bottom: 0;
    color: #ffffff;
}

.text-container {
    max-width: 90%; /* Ajusta el ancho máximo para pantallas más pequeñas */
}

.text-container .tuerca {
    width: 5%; 
    height: auto; 
    margin-top: 1%; 
    margin-left: 1.5%;
}

.line-revelador {
    border-top:  .001vw solid #ffffff; 
    margin-top: -2%;
    width: 75%;    
    margin-left: 9%; 
    opacity: 0.5; 
    }



.carousel-indicators.custom-indicators button {
    background-color: rgba(255, 255, 255, 0.6); 
    border: none;
    width: .5vw;
    height: .5vw;
    margin: 0 0.3vw;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 0; 
}

.carousel-indicators.custom-indicators .active {
    background-color: rgba(255, 255, 255, 1); 
}

.content-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2%;
    background-color: #fff;
}

.content-right {
    flex: 1;
    text-align: left;
    margin-right: 0%;
    margin-left: -5%;
    margin-bottom: 0;
    margin-top: 18%;
}

 .content-right h2 {
    font-size: 3vw;
    color: #000;
    font-weight: 700;
}

.content-right h3 {
    font-size: 2.1vw;
    color: #4D4D4D;
    font-weight: 500;
    margin-top: -2%;
}

.content-right h4 {
    font-size: 1.3vw;
    color: #4D4D4D;
    font-weight: 700;
}

.content-right p {
    font-size: 1.1vw; 
    color: #4D4D4D; 
    font-family: 'Montserrat', sans-serif;
}

.content-left {
    flex: 1;
    text-align: right;
    padding: 0 2%;
}

.content-left img {
    width: 70%;
    margin-left: 15%;
    height: auto;
    margin-top: 20%;
    margin-bottom: 6%;
}


.btn-group {
    display: flex;
    gap: 2vw; /* Usa vw para espacio entre botones */
}

/* Estilos para los enlaces de botones */
.button-link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: .5vw 1.5vw; /* Usa vw para padding */
    border-radius: 50px;
    text-decoration: none;
    color: #4D4D4D;
    background-color: #fff;
    transition: background-color 0.3s ease, color 0.3s ease;
    border: 1px solid #4D4D4D;
    font-size: 1vw; /* Usa vw para tamaño de fuente */
}

/* Icono dentro del botón */
.button-link i {
    margin-left: 1vw; /* Usa vw para margen izquierdo del icono */
    transition: transform 0.3s ease;
    transform: rotate(45deg);
}

/* Hover effects para el botón */
.button-link:hover {
    background-color: #E42620;
    color: #fff;
}

/* Hover effect para el icono */
.button-link:hover i {
    transform: translateX(0.5vw); /* Usa vw para transformar icono */
    transform: rotate(45deg);
}




.btn-group-blog {
    display: flex;
    gap: 2vw; /* Usa vw para espacio entre botones */
    margin-left: 1.5vw;
}

.btn-group-blog-one-three {
    display: flex;
    gap: 2vw; /* Usa vw para espacio entre botones */
    margin-left: 1.5vw;
    margin-top: 2.8vw;
}

.button-link-blog {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: .5vw 1.5vw; /* Usa vw para padding */
    border-radius: 50px;
    text-decoration: none;
    color: #4D4D4D;
    background-color: #fff;
    transition: background-color 0.3s ease, color 0.3s ease;
    border: 1px solid #4D4D4D;
    font-size: 1vw; /* Usa vw para tamaño de fuente */
}

/* Icono dentro del botón */
.button-link-blog i {
    margin-left: 0vw; /* Usa vw para margen izquierdo del icono */
    transition: transform 0.3s ease;
    transform: rotate(45deg);
}

/* Hover effects para el botón */
.button-link-blog:hover {
    background-color: #E42620;
    color: #fff;
}

/* Hover effect para el icono */
.button-link-blog:hover i {
    transform: translateX(0.5vw); /* Usa vw para transformar icono */
    transform: rotate(45deg);
}


.carrouselDesktop{
    padding: 0%;
    height: 50vw;
    position: relative;
}

.background-image {
    width: 100%;
    height: auto;
}

.carousel-overlay {
    position: absolute;
    top: 0;
    left: 20%;
    width: 60%;
    height: 100%;
}

.carousel-inner-producto-desktop {
    display: flex;
    top: 13%;
    height: 100%;
}

.card-desktop{
    padding: 0%;
    height: 33.5vw;
    border: none;
}

.card-header{
    background-image: linear-gradient(to right, #A41916, #E42620);
    color:#ffffff;
    align-items: center;
    text-align: center;
    font-family: 'Poppins';
    font-weight: normal;
    font-size: 1vw;
}

.col-desktop-carrousel{
    padding: 0%;
}

.col-desktop-carrousel-2{
    height: 100%;
}

.col-desktop-carrousel-3-img{
    padding-top: 0%;
    padding-left: 0%;
    padding-right: 0%;
    padding-bottom: 0%;
    height: 100%;
    text-align: left;
}

.col-desktop-carrousel-3{
    padding-top: 0%;
    padding-left: 0%;
    padding-right: 0%;
    padding-bottom: 2%;
    height: 100%;
    text-align: left;
}

.text-carrousel-desktop{
    padding-top: 2%;
    padding-left: 2%;
}

.text-carrousel-desktop-title{
    font-size: 1.5vw;
    font-weight: bold;
    font-family: 'Poppins';
    margin-bottom: 0%;
}

.text-carrousel-desktop-title-2{
    font-size: 1.5vw;
    font-weight: bold;
    font-family: 'Poppins';
    margin-bottom: 7.5%;
}

.text-carrousel-desktop-text{
    font-size: 0.8vw;
    font-family: 'Poppins';
    margin-bottom: 12%;
}

.text-carrousel-desktop-text-2{
    font-size: 0.8vw;
    font-family: 'Poppins';
    margin-bottom: 12%;
}



.btn-group-carrousel {
    display: flex;
    gap: 2vw; /* Usa vw para espacio entre botones */
    margin-left: 1.5vw;
    margin-top: 2.8vw;
}

.button-prev-carrousel {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: .5vw 1.5vw; /* Usa vw para padding */
    border-radius: 50px;
    text-decoration: none;
    color: #ffffff;
    background-image: linear-gradient(to right, #A41916, #E42620);
    transition: background-color 0.3s ease, color 0.3s ease;
    font-size: 1vw; /* Usa vw para tamaño de fuente */
}

/* Icono dentro del botón */
.button-prev-carrousel i {
    margin-left: 0vw; /* Usa vw para margen izquierdo del icono */
    transition: transform 0.3s ease;
    transform: rotate(235deg);
}

.button-prev-carrousel:hover {
    background-image: linear-gradient(to right, #8d0402, #E42620);
    border: 0.1vw solid #000000;
}

.button-next-carrousel {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: .5vw 1.5vw; /* Usa vw para padding */
    border-radius: 50px;
    text-decoration: none;
    color: #ffffff;
    background-image: linear-gradient(to right, #A41916, #E42620);
    font-size: 1vw; /* Usa vw para tamaño de fuente */
}

/* Icono dentro del botón */
.button-next-carrousel i {
    margin-left: 0vw; /* Usa vw para margen izquierdo del icono */
    transition: transform 0.3s ease;
    transform: rotate(45deg);
}

.button-next-carrousel:hover {
    background-image: linear-gradient(to right, #8d0402, #E42620);
    border: 0.1vw solid #000000;
}

.color-col{
    background-color: #F2F2F2;
}

.container-fluid.products{
    padding: 0%;
    height: 50vw;
    position: relative;
}

.container-fluid img{
    width: 100%;
    
}

.product {
    color:#ffffff;
    padding: 0%;
    background-position: center; 
    border-top-left-radius: 1.5vw;
    border-top-right-radius: 1.5vw;
    font-family: 'Poppins';
    font-size: 1vw;
    border: none;
}
.imgProd{

    padding: 0%;
    background-color: #ffffff;
}

.title-products{
    font-family: 'Poppins';
    font-size: 1vw;
}
.tableProducts{
    padding: 0%;
    
}

.card-1{
    background-color: #ffffff;
    font-family: 'Poppins';
    font-size: 1.5vw;
    text-align: left;
    font-weight: bold;
    border: none;
    border-bottom-left-radius: 1vw;
    height: 17vw;
    padding-left: 2%;
    padding-right: 2%;
}
.card-2{
    background-color: #F2F2F2;
    font-family: 'Poppins';
    font-weight: bold;
    font-size: 1.5vw;
    text-align: left;
    border: none;
    border-bottom-right-radius: 1vw;
    padding-left: 2%;
    padding-right: 2%;
}

.sub-titles{
    margin-top: 5%;
    margin-bottom: 2%;
}

.sub-titles-2{
    margin-top: 5%;
    margin-bottom: 2.8vw;
}

.card-text {
    text-align: center;
    text-align: justify;
    font-family: 'Poppins';
    font-size: 0.9vw;
    font-weight: normal;
    padding-bottom: 5%;
}

.container-fluid.blog{
   margin-bottom: 5%;
}

.title-blog{
    font-family: 'Poppins';
    font-size: 2vw;
    font-weight: lighter;
    margin-bottom: 4%;
    color: #4D4D4D;
}

.blog-img{
    padding-left: 0;
}

.card-text-blog{
    padding: 2%;
}


.text-date{
    font-family: 'Poppins';
    text-align: left;
    font-size: 0.9vw;
    padding-bottom: 0%;
    margin-left: 6%;
    color: #808080;
}

.text-blog{
    padding-top: 0%;
    font-family: 'Poppins';
    text-align: left;
    font-size: 1.2vw;
    color: #4D4D4D;
    line-height: 1.3;
    margin-left: 6%;

}

.img-blog-1{
    height: auto;
    width: 30%;
    margin-right: 3%;
    margin-left: 2%;
}

.img-blog-2{
    height: auto;
    width: 30%;
    margin-right: 3%;
}

.img-blog-3{
    height: auto;
    width: 30%;
    margin-right: 2%;
}

.img-logo-zip{
    width: 7%;
    height: auto;
}

.img-logo-symsa{
    width: 5%;
    height: auto;
    position: relative;
    right: 27%;
    top: -0.7vw;
}

.img-logo-symsa-static{
    width: 5%;
    height: auto;
    position: relative;
    right: 27%;
    top: -0.5vw;
}

.logo-text-color{
    color: #ffffff;
    font-size: 1.5vw;
    font-weight: bold;
}

.logo-text-color-scroll{
    color: #000000;
    font-size: 1.5vw;
    font-weight: bold;
}

.footer{
    height: 17vw;
    padding: 0%;

}

.direccion{
    height: 17vw;
    padding-right: 0%;
    padding-left: 0%;
    padding-top: 5%;
    padding-bottom: 10vw;
}

.productos-footer{
    height: 17vw;
    padding-top: 1%;
    padding-right: 0%;
    padding-left: 0%;
    position: relative;
}

.contacto{
    height: 17vw;
    padding-right: 0%;
    padding-left: 0%;
    text-align: left;
}

.img-footer{
    width: 100%;
    
}

.footer-bar{
    width: 100%;
    height: 35%;
}

.title-footer{
    font-family: 'Poppins';
    font-weight: bold;
    font-size: 1.2vw;
}

.sub-text-footer{
    font-family: 'Poppins';
    font-weight: normal;
    font-size: 1.2vw;
}

.sub-text-footer-2{
    font-family: 'Poppins';
    font-weight: normal;
    font-size: 1vw;
    margin-bottom: 0%;
    text-align: left;
}

.sub-text-footer-3{
    font-family: 'Poppins';
    font-weight: normal;
    font-size: 0.7vw;
    margin-top: 1%;
}
   .no-style-link {
        text-decoration: none;
        color: inherit;
    }

    .phone-link {
        text-decoration: none;
        color: inherit;
        margin-bottom: 0%;
    }

.title-footer,
.sub-text-footer {
    display: inline-block;
    margin: 0;
    padding: 0.2vw;
}
.footer-location {
    display: flex;
    align-items: center;
    font-size: 1.8vw;
}


.icon-phone-footer i {
    font-size: 1.8vw;

    
}

.footer-location i {
    margin-right: 1%; 
    margin-left: 20%;
}

.text-over-image {
    position: absolute;
    top: 12%;
    left: 32%; 
    font-family: 'Poppins';
    font-size: 2vw;
    font-weight: lighter;
    color: #ffffff;
}

.text-2-over-image {
    position: absolute;
    top: 34%;
    left: 6%; 
    font-family: 'Poppins';
    font-size: .8vw;
    font-weight: lighter;
    color: #ffffff;
}

.text-3-over-image {
    position: absolute;
    top: 43%;
    left: 57%; 
    font-family: 'Poppins';
    font-size: .8vw;
    font-weight: lighter;
    color: #ffffff;
}

.text-4-over-image {
    position: absolute;
    top: 43%;
    left: 6%; 
    font-family: 'Poppins';
    font-size: .8vw;
    font-weight: lighter;
    color: #ffffff;
}
.text-5-over-image {
    position: absolute;
    top: 53%;
    left: 57%;
    font-family: 'Poppins';
    font-size: .8vw;
    font-weight: lighter;
    color: #ffffff;
}

.text-6-over-image {
    position: absolute;
    top: 53%;
    left: 6%; 
    font-family: 'Poppins';
    font-size: .8vw;
    font-weight: lighter;
    color: #ffffff;
}

.text-7-over-image {
    position: absolute;
    top: 63%;
    left: 57%; 
    font-family: 'Poppins';
    font-size: .8vw;
    font-weight: lighter;
    color: #ffffff;
}

.text-8-over-image {
    position: absolute;
    top: 63%;
    left: 6%; 
    font-family: 'Poppins';
    font-size: .8vw;
    font-weight: lighter;
    color: #ffffff;
}

.text-9-over-image {
    position: absolute;
    top: 73%;
    left: 57%;
    font-family: 'Poppins';
    font-size: .8vw;
    font-weight: lighter;
    color: #ffffff;
    text-align: left;
   
}

.text-10-over-image {
    position: absolute;
    top:73%;
    left: 6%; 
    font-family: 'Poppins';
    font-size: .8vw;
    font-weight: lighter;
    color: #ffffff;
}

.text-11-over-image {
    position: absolute;
    top: 83%;
    left: 57%;
    font-family: 'Poppins';
    font-size: .8vw;
    font-weight: lighter;
    color: #ffffff;
}

.text-12-over-image {
    position: absolute;
    top: 83%;
    left: 6%; 
    font-family: 'Poppins';
    font-size: .8vw;
    font-weight: lighter;
    color: #ffffff;
}

.text-13-over-image {
    position: absolute;
    top: 65%;
    left: 57%; 
    font-family: 'Poppins';
    font-size: .8vw;
    font-weight: lighter;
    color: #ffffff;
}

.text-14-over-image {
    position: absolute;
    top: 34%;
    left: 57%; 
    font-family: 'Poppins';
    font-size: .8vw;
    font-weight: lighter;
    color: #ffffff;
}


.text-15-over-image {
    position: absolute;
    top: 74%;
    left: 57%; 
    font-family: 'Poppins';
    font-size: .8vw;
    font-weight: lighter;
    color: #ffffff;
}

.footer-bar-2{
    width: 100%;
    height: 5%;
}

.contact-footer{
    margin-top: 15%;
    margin-left: 13%;
    font-size: 1.2vw;
    font-family: 'Poppins';
    font-weight: bold;
    margin-bottom: 0%;
}

.contact-footer-2{
    font-size: 0.9vw;
    font-family: 'Poppins';
}

.icon-phone-footer,
.contact-footer-2 {
            display: inline-block;
            margin: 0;
            padding: 0 1%;
        }
.contact-position{
    margin-left: 13%;
    margin-bottom: 4%;
}


.container-footer-info {
    display: flex;
    align-items: center; 
    margin-left: 13%;
}

.aviso-privacidad{
    font-family: 'Poppins';
    font-weight: normal;
    font-size: 0.7vw;
    margin: 0;
}

.icon-visual{
    width: 10%;
    height: auto;
}

.white {
    background-color: white !important;
    transition: background-color 0.5s ease;
}

.positionNavOriginal{
    position: fixed;
    top: 0%;
    left: 0;
    width: 100%;
    z-index: 1001;
    margin-top: -1vw;
    
}

.positionNavScroll{
    position: fixed;
    top: 2.8vw;
    left: 0;
    width: 100%;
    z-index: 1001;
   
}

.title-nosotros{
    padding-top: 11%;
    font-weight: bold;
    font-size: 3vw;
}
.nav-symsa {
    /* Añade flexbox */
    display: flex;
    /* Centra horizontalmente */
    justify-content: space-between;
    /* Alinea verticalmente si es necesario */
    align-items: center;
    padding-top:4%;
    padding-bottom: 2%;
    height: 7.5vw;
  }

  .navbar-brand.navbar-text-white{
    color:#ffffff;
    font-family: 'Poppins';
    font-weight: normal;
    font-size: 1vw;
    margin-top: 1.5%;
    margin-bottom: 3%;
}

.navbar-brand.navbar-text-black{
    color:#000000;
    font-family: 'Poppins';
    font-weight: normal;
    font-size: 1vw;
    margin-top: 1.5%;
    margin-bottom: 3%;
}

.btn-nav.button-link-nav {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: .5vw 1.5vw; /* Usa vw para padding */
    border-radius: 50px;
    text-decoration: none;
    color: #ffffff;
    background-color: transparent;
    transition: background-color 0.3s ease, color 0.3s ease;
    border: 0.1vw solid #ffffff;
    font-size: 1vw; /* Usa vw para tamaño de fuente */
    margin-bottom: 3vw;
    margin-top: 1.4vw;
}

.btn-nav.button-link-nav-scroll {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: .5vw 1.5vw; /* Usa vw para padding */
    border-radius: 50px;
    text-decoration: none;
    color: #000000;
    background-color: transparent;
    transition: background-color 0.3s ease, color 0.3s ease;
    border: 0.1vw solid #000000;
    font-size: 1vw; /* Usa vw para tamaño de fuente */
    margin-bottom: 3vw;
    margin-top: 1.4vw;
}

.btn-nav-static.button-link-nav-scroll {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: .5vw 1.5vw; /* Usa vw para padding */
    border-radius: 50px;
    text-decoration: none;
    color: #000000;
    background-color: transparent;
    transition: background-color 0.3s ease, color 0.3s ease;
    border: 0.1vw solid #000000;
    font-size: 1vw; /* Usa vw para tamaño de fuente */
    margin-bottom: 3vw;
    margin-top: 0.5vw;
}

.navbar-brand:hover {
    color: #E42620;
}

.header-symsa{
    padding-top: 1%;
    padding-bottom: 1%;
    background-image: linear-gradient(to right, #A41916, #E42620);
}

.text-header{
    font-size: 1vw;
    color: #ffffff;
    font-family: 'Poppins';
    font-weight: normal;
}

.container-head{
    padding-left: 0%;
    padding-right: 0%;   
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999; /* Ajusta según sea necesario para asegurarte de que el contenedor esté en la parte superior */
}

/* Icono dentro del botón */
.button-link-nav-scroll i {
    margin-left: 1vw; /* Usa vw para margen izquierdo del icono */
    transition: transform 0.3s ease;
    transform: rotate(45deg);
}

/* Hover effects para el botón */
.button-link-nav-scroll:hover {
    background-color: #E42620;
    color: #fff;
}

/* Hover effect para el icono */
.button-link-nav-scroll:hover i {
    transform: translateX(0.5vw); /* Usa vw para transformar icono */
    transform: rotate(45deg);
}





/* Icono dentro del botón */
.button-link-nav i {
    margin-left: 1vw; /* Usa vw para margen izquierdo del icono */
    transition: transform 0.3s ease;
    transform: rotate(45deg);
}

/* Hover effects para el botón */
.button-link-nav:hover {
    background-color: #E42620;
    color: #fff;
    
}

/* Hover effect para el icono */
.button-link-nav:hover i {
    transform: translateX(0.5vw); /* Usa vw para transformar icono */
    transform: rotate(45deg);
}

.icons-nav{
    width: 5%;
    height: auto;
    position: relative;
    left: 28%;
    top:0.5vw;
    display: flex;
    flex-direction: column; /* Alinea los elementos en una columna */
    align-items: center; /* Centra los elementos horizontalmente */
    gap: 2.5vw;
    font-size: 1.4vw;
}


.color-icon:hover {
    color: #E42620;
} 

.color-icon-scroll:hover {
    color: #E42620;
}
.color-icon{
    color: #ffffff;
}

.color-icon-scroll{
    color: #4D4D4D;
}

.social-icons:hover {
    color: #E42620;
}

/* Estilos para hacer el scroll invisible */
::-webkit-scrollbar {
    width: 0;
    height: 0;
}

::-webkit-scrollbar-thumb {
    border: none;
}

::-webkit-scrollbar-track {
    border: none;
}

.up-arrow {
    position: absolute;
    display: inline-flex;
    margin: 10% auto;
    width: 2vw;
    height: 4vw;
    top: -6vw;
    right: 4vw;
    background-image: linear-gradient(to right, #A41916, #E42620);
    color: white;
    border-radius: 15px;
    text-align: center;
    font-size: 1vw;
    font-weight: 200;
    text-decoration: none;
}

.banner-white{
    height: 30%;
    background-color: #ffffff;
}
.nosotros{
    padding: 0%;
    margin-bottom: 2%;   
}
.title-nosotros{
    padding-top: 11%;
    font-weight: bold;
    font-size: 3vw;
}

.container-head-static{
    padding-left: 0%;
    padding-right: 0%;   
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1001;
}

.positionStatic{
    position: fixed;
    left: 0;
    width: 100%;
    height: 10vw;
    z-index: 1000;
    background-color: #ffffff !important;
    
}

.position-nosotros{
    margin-top: 3%;
    
}

.obj-title{
    font-weight: bold;
    color: #4D4D4D !important;
    font-size: 2.2vw;
}
.col-nosotros{
    font-family: 'Poppins', sans-serif;
     
}
.col-nosotros-flex{
    font-family: 'Poppins', sans-serif;
    display: flex;
    align-items: center; 
    padding: 0%;
}
.linea-nosotros-red {
    background: linear-gradient(to right, #A41916, #E42620);
    margin-right: 0.1vw;
}
.linea-nosotros-black {
    background: linear-gradient(to right, #363636, #000000);
    margin-left: 0.1vw;
}
.linea-nosotros-red, .linea-nosotros-black {
    height: 20%; 
    width: 50%; 
    margin-bottom: 2%;
}

.padding-col{
    padding: 2.5%;
}

.text-nosotros-col{
    color: #4D4D4D;
    font-size: 1vw;
    font-family: 'Poppins', sans-serif;
    text-align: justify;
    
}

.text-nosotros-col-valores{
    color: #4D4D4D;
    font-size: 1vw;
    font-family: 'Poppins', sans-serif;
    text-align: justify;
}

.text-nosotros-col.color-historia-text{
    color: #ffffff !important;
    text-align: left;
    margin-left: 4%;
    margin-top: 4%;
    margin-right: 4%;
    text-align: justify;
}
.icon-number{
    width:4%;
    height: auto;
    margin-right: 1%;
}
.icon-number-2{
    width:4%;
    height: auto;
    margin-right: 1%;
    
}

.icon-number-two{
    width:6%;
    height: auto;
    margin-right: 1%;
    
}
.div-text-obj{
    text-align: left;
    width: 50%;
}

.div-text-obj-2{
    text-align: left;
    padding-bottom: 0%;
}
.size-col-nosotros{
    height: 40vw;
    background-color: #000000;
    text-align: center;
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    font-size: 2vw;
    font-weight: normal;
    padding-top: 5%;
    
}

.vision-mision{
    margin-top: 4%;
    
}

.mision-text{
    text-align: left;
    width: 50%;
    padding-right: 3%;
}

.color-a{
    border-width: 1px;
  border-style: solid;
  border-color: black;
}

.valores{
    padding: 0%;
    position: relative;

}

.valores .overlay {
    position: absolute;
    top: 10%;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.valores .overlay .row {
    width: 100%;
}

.valores-title{
    color: #ffffff;
    font-size:1.8vw;
    position: absolute;
    top: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-valores{
    width: 10%;
    height: auto;
    margin-bottom: 2%;
}

.text-valores{
    color: #ffffff;
    font-size: 1.4vw;
    font-family: 'Poppins', sans-serif;
    margin-top: 35%;
    margin-left: 20%;
}

.color-text-valores{
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    text-align: left;
    text-align: justify;
    font-size: 1vw;
    margin-bottom: 1%;
}

.contacto-symsa{
    border: 1px solid #000; /* Solo para visualización */
}

.position-container-contacto{
    display: flex;
    margin-top: 14%;
    align-items: center;
    text-align: center;
}
.title-contacto{
    font-size: 2.5vw;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    padding-right: 15%;
    padding-bottom: 5%;
}
.title-contacto-2{
    font-size: 2vw;
    font-family: 'Poppins', sans-serif;
    color: #4D4D4D;
    font-weight: normal;
    margin-right: 24%;
    margin-bottom: 7%;
}
.input-contact{
    border: 0.1vw solid #A8A8A8; /* Solo para visualización */
    border-radius: 1vw;
    background-color: #ffffff;
    color: #A8A8A8;
    font-size: 1vw;
    margin-bottom: 3%;
    width: 30vw;
    height: 3vw;
    margin-right: 5%;
    padding-left: 2%;
}

.input-contact.intup-comments{
    height: 12vw;
    padding-bottom: 9vw;
}

.formulario-contacto{
    text-align: right;
}

.btn-contact{
    position: relative;
    left: 28%;
    top: -3vw;
}

.text-oblig{
    color: #A8A8A8;
    font-size: 1vw;
    position: relative;
    right: 6%;
}

.datos-contacto{
    text-align: left;
    padding-left: 11%;
    padding-bottom: 13%;
}

.title-direccion{
    margin-top: 0%;
    margin-bottom: 0%;
}
.text-contacto{
    color: #4D4D4D;
    font-size: 1vw;
    margin-bottom: 0%;
    
}
.icon-contact{
    width: 1.2vw;
    height: auto;
    display: flex;
    align-items: center;
}
.banner-contact-footer{
    padding: 0%;
    margin-top: 0%;
}

/* styles view productos */

.container-view-productos{
    padding-left: 0%;
    padding-right: 0%;
    padding-top: 15%;
    padding-bottom: 10%;
}

.productos-desktop {
    margin-top: 0%;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 0%; /* Espacio entre los contenedores */
    padding-top: 2%;
    padding-left: 20%;
    padding-right: 20%;
}

.cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5%;
    justify-content: center; /* Centrar las tarjetas en el contenedor */
}

.card-wrapper {
    display: flex;
    flex: 1 1 calc(50% - 20px); /* Ajustar el tamaño para dos tarjetas por fila con espacio entre ellas */
    max-width: calc(50% - 20px); /* Máximo ancho de cada tarjeta */
}

.card-desktop-productos{
    padding: 0%;
    flex: 1;
    min-width: 300px; /* Asegurar un tamaño mínimo */
    margin-bottom: 20px; /* Espacio inferior entre filas */

}

.body-card-productos{
    padding: 0%;
}

.content-card-productos{
    padding: 5%;
}

.header-card-prod{
    background-image: linear-gradient(to right, #ffffff, #ffffff);
    color:#ffffff;
    align-items: center;
    text-align: center;
    font-family: 'Poppins';
    font-weight: normal;
    font-size: 1vw;
}
.second-card .header-card-prod {
    background-image: linear-gradient(to right, #F2F2F2, #F2F2F2);
    color: #F2F2F2;
}

.body-card-productos-background{
    background-image: linear-gradient(to right, #F2F2F2, #F2F2F2);
    
}

.body-card-productos-background-2{
    background-image: linear-gradient(to right, #F2F2F2, #F2F2F2);
}

.title-productos{
    font-family: 'Poppins';
    font-weight: bold;
    font-size: 2vw;
    color: #000000;
    margin-left: 20%;
}

.sub-title-productos{
    font-family: 'Poppins';
    font-size: 0.9vw;
    color: #000000;
    margin-left: 20%;
}




/*PRODUCTOS*/

.productos-detalles{
    margin-top: 15%;
    padding: 0%;    
    text-align: center;
    
}
.title-details{
    font-family: 'Poppins';
    font-size: 1vw;
    color: #000000;
    margin-bottom: 1%;
}
.title-details-products{
    font-family: 'Poppins';
    font-size: 2.5vw;
    color: #000000;
    font-weight: bold;
    margin-bottom: 2%;
}


.info-products {
    padding: 0%;
    display: grid;
    grid-template-columns: 1fr 1fr; /* Dos columnas de igual tamaño */
    gap: 0; /* Espacio entre columnas */
    
}

.img-product-details {  
    padding: 0%;
}


.description-product {
    padding-top: 2%;
    background-image: linear-gradient(to right, #000, #000);
    position: relative;
}


.text-details-products{
    font-size: .9vw;
    font-family: 'Poppins';
    margin-bottom: 12%;
    color: #ffffff;
    text-align: justify;
    padding: 2%;
}



.card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap; /* Prevent cards from wrapping to the next line */
    gap: 1%; /* Space between cards */
    
}

.card-list-products{
    border-radius: 0%;
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-top: 5px solid; /* Ajusta el grosor del borde */
    border-image: linear-gradient(to right, #A41916, #E42620) 1;
    width: 100%;
}

.card-list-products-2{
   
    border-image: linear-gradient(to right, #363636, #000000) 1;
   
}

.title-details-2{
    font-size: 2vw;
    font-family: 'Poppins';
    color: #4D4D4D;
    margin-top: 2%;
    margin-bottom: 2%;
}
.text-list-products{
    font-family: 'Poppins';
    color: #4D4D4D;
    font-size: .8vw;
    text-align: left;
    font-weight: bold;
    
}

.card-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centra las filas horizontalmente */
}

.cards-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Máximo 4 columnas */
    gap: 10px; /* Ajusta el espacio entre las tarjetas según sea necesario */
    width: 100%;
}

.container-cards-products{
    padding-left: 0%;
    padding-right: 0%;
}
.card-products-symsa{
    padding-left: 5%;
}
.btn-details-products{
    margin-top: 2%;
   
}
.menu-footer{
    background-color: #000;
}
.button-link-nav-details {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: .5vw 1.5vw; /* Usa vw para padding */
    border-radius: 50px;
    text-decoration: none;
    color: #ffffff;
    background-image: linear-gradient(to right, #A41916, #E42620);
    transition: background-color 0.3s ease, color 0.3s ease;
    border: 0.1vw solid #000;
    font-size: 1vw; /* Usa vw para tamaño de fuente */
    margin-bottom: 3vw;
}
.col-details-products{
    padding-top: 3%;
    padding-left: 12.5%;
}



.slides-productos{
    height: 10%;
    width: 30%;
    position: absolute;
    top: 90%;
    left: 0%;
    background-image: linear-gradient(to right, #A41916, #E42620);
    text-align: center;
    align-items: center;
    overflow: hidden;
}

.item-slide{
    display: none; /* Oculta todos los slides inicialmente */
    width: 100%;
    white-space: normal;
    transition: opacity 1s ease;
    opacity: 0;
    position: absolute;
    color: #ffffff;
    font-family: 'Poppins';
    font-size: 1vw;
    padding-top:3%;
    padding-right: 10%;
}

.item-slide.active {
    display: block; /* Muestra el slide activo */
    opacity: 1;
}

.dowload-catalogo{
    position: absolute;
    top:86%;
    left:30%;
    width: 25%;
    padding: 0%;
   
}
.size-icon-dowload {
    display: inline-block; /* Cambiamos a inline-block para que pueda colocarse al lado del párrafo */
    vertical-align: middle; /* Alineación vertical */
    height: 70%;
    width: 1.5vw;
}
.size-icon-dowload img {
    max-width: 100%; /* Ajusta el tamaño máximo de la imagen */
    height: auto; /* Permite que la altura se ajuste automáticamente */
}
.text-catalogo {
    margin-top: 1.2vw;
    display: inline-block; /* Mostramos como inline-block */
    color: #ffffff;
    font-family: 'Poppins';
    font-size: 1vw;
    width: calc(70% - 1vw); /* Ajustamos el ancho del párrafo, restando el ancho del icono */
    vertical-align: middle; /* Alineación vertical */
    padding-left: 0%;
}

@media screen and (max-width: 767px) {

    
    html, body {
        margin: 0;
        padding: 0;
        height: 100%;
        overflow-x: hidden;
        font-family: 'Poppins', sans-serif;
    }

    .text-container {
        position: absolute;
        top: 25%;
        left: 7%;
        z-index: 1;
        color: #FFF;
        display: flex;
        flex-direction: column;
    }
    
    .logo {
        font-size: 16vw;
        font-weight: 700;
        margin: 0;
    }

    

    
.text-container .tuerca {
    width: 9%; 
    height: auto; 
    margin-top: 2%; 
    margin-left: 1.5%;
}

.line-revelador {
    border-top:  .01vw solid #ffffff; 
    margin-top: -5%;
    width: 62%;    
    margin-left: 15%; 
    opacity: 0.6; 
    }
    
    .innovations{
        font-size: 4vw;
        font-weight: 300;
        color: #fff;
        margin-top: 7%;
        margin-bottom: 1%;
    }
    
    .sense{
        font-size: 4vw;
        font-weight: 300;
        color: #fff;
        margin-bottom: 1%;
    }
    
    
    .beauty {
        font-size: 4vw;
        font-weight: 300;
        color: #fff;
    }
    
    .subtext {
        font-size: 8.5vw;
        margin-top: -5%;
        font-weight: 700;
        margin-bottom: 0;
        color: #ffffff;
    }

    .carousel-indicators.custom-indicators button {
        background-color: rgba(255, 255, 255, 0.6); 
        border: none;
        width: 2vw;
        height: 2vw;
        margin: 0 0.3vw;
        cursor: pointer;
        transition: background-color 0.3s ease;
        border-radius: 0; 
    }
    
    .carousel-indicators.custom-indicators .active {
        background-color: rgba(255, 255, 255, 1); 
    }

    
    .content-section {
        flex-direction: column; 
        padding: 2% 0; 
        font-family: 'Poppins';
    }

    .content-left, .content-right {
        flex: none; 
        width: 100%; 
        margin: 0; 
        text-align: left; 
        padding: 0 4%; 
    }

    .content-right h2 {
        font-size: 9vw;
        color: #000;
        font-weight: 700;
        
    }
    
    .content-right h3 {
        font-size: 6.8vw;
        color: #4D4D4D;
        font-weight: 400;
        margin-top: -3%;
    }
    
    .content-right h4 {
        font-size: 4.8vw;
        color: #4D4D4D;
        font-weight: 700;
        margin-top: -3%;
    }

    .content-right p {
        font-size: 4.3vw; 
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        margin-left: 0%;
        margin-top: -1%;
    }

    .content-left img {
        width: 100%; 
        margin-top: 20%; 
        margin-bottom: 3%; 
        align-items: center;
        margin-left: 0%; 

    }

    
    .button-group{
        align-items: center;
        
        display: inline-flex;
        
        gap: 10%;
        flex-wrap: nowrap;
        
    }

    .button-link {
        display: inline-flex;
        justify-content: left;
        text-align: left;
        width: 80%;
        padding: 2% 9%; 
        border-radius: 50px;
        text-decoration: none;
        color: #4D4D4D;
        background-color: #fff;
        transition: background-color 0.3s ease, color 0.3s ease;
        border: 1px solid #4D4D4D;
        font-size: 4vw; /* Usa vw para tamaño de fuente */
    }
    
    /* Icono dentro del botón */
    .button-link i {
        margin-left: 6vw; /* Usa vw para margen izquierdo del icono */
        transition: transform 0.3s ease;
        transform: rotate(45deg);
        font-size: 5vw;
    }
    
    /* Hover effects para el botón */
    .button-link:hover {
        background-color: #E42620;
        color: #fff;
    }
    
    /* Hover effect para el icono */
    .button-link:hover i {
        transform: translateX(0.5vw); /* Usa vw para transformar icono */
        transform: rotate(45deg);
    }

    .container-fluid.products{
        position: relative;
    }

    .fondo {
        position: absolute;
        z-index: -1;
    }
  
    .card-product{
        height: 120vw;
        margin-left: 0%;
        margin-right: 0%;
        padding-left: 0%;
        padding-right: 0%;
        padding-top: 0%;
        width: 98vw;
    }

    .card-img-top{
        width: 100%;
        margin-top: -1%;
        margin-bottom: -1%;
    }

    

    .card-header{
        background-image: linear-gradient(to right, #A41916, #E42620);
        font-size: 6vw;
        text-align: center;
        font-weight: 200;
        color: #FFF;
        padding: 7%;
        overflow: hidden;
        border-top-left-radius: 15px; /* Redondea la esquina superior izquierda */
        border-top-right-radius: 15px; /* Redondea la esquina superior derecha */

    }

    .card{
     background-color: transparent;
     overflow: hidden;
    }

    .card-body{
        background-color: #fff;
        overflow: hidden;
        border-bottom-left-radius: 15px; /* Redondea la esquina inferior izquierda */
        border-bottom-right-radius: 15px; /* Redondea la esquina inferior derecha */
    }

    .card-title{
        font-weight: 700;
        font-size: 7vw;
    }

    .card-text{
        font-size: 4vw;
        text-align: left;
    }

    .button-group-movil{
        align-items: center;
        
        display: inline-flex;
        
        gap: 10%;
        flex-wrap: nowrap;
        
    }

    .button-link2 {
        display: inline-flex;
        justify-content: left;
        text-align: left;
        width: 40%;
        padding: 2% 9%; 
        border-radius: 50px;
        text-decoration: none;
        color: #4D4D4D;
        background-color: #fff;
        transition: background-color 0.3s ease, color 0.3s ease;
        border: 1px solid #4D4D4D;
        font-size: 4vw; /* Usa vw para tamaño de fuente */
    }
    
    /* Icono dentro del botón */
    .button-link2 i {
        margin-left: 6vw; /* Usa vw para margen izquierdo del icono */
        transition: transform 0.3s ease;
        transform: rotate(45deg);
        font-size: 5vw;
    }
    
    /* Hover effects para el botón */
    .button-link2:hover {
        background-color: #E42620;
        color: #fff;
    }
    
    /* Hover effect para el icono */
    .button-link2:hover i {
        transform: translateX(0.5vw); /* Usa vw para transformar icono */
        transform: rotate(45deg);
    }

    #carouselExample .carousel-indicators.custom-indicators button {
        background-color: rgba(255, 255, 255, 0.6); 
        border: none;
        width: 2vw;
        height: 2vw;
        cursor: pointer;
        transition: background-color 0.3s ease;
        border-radius: 0; 
    }
    
    #carouselExample  .carousel-indicators.custom-indicators .active {
        background-color: rgba(255, 255, 255, 1); 
    }

    .title-blog{
        font-family: 'Poppins';
        font-size: 11vw;
        font-weight: 200;
        margin-bottom: 4%;
        color: #4D4D4D;
        text-align: center;
    }

    #carouselBlog .card{
        background-color: #fff;
        overflow: hidden;
        border: none;
        border-color: transparent;
      
    }

    #carouselBlog .carousel-indicators.custom-indicators button {
        background-color: rgba(77, 77, 77, 0.6); 
        border: none;
        width: 2vw;
        height: 2vw;
        cursor: pointer;
        transition: background-color 0.3s ease;
        border-radius: 0; 
    }
    
    #carouselBlog  .carousel-indicators.custom-indicators .active {
        background-color: rgba(77, 77, 77, 1); 
    }

    #carouselBlog .card-title{
        font-weight: 200;
        font-size: 4vw;
        color: #808080;
    }

    #carouselBlog .card-text{
        font-size: 5vw;
        text-align: left;
        color: #4D4D4D;
    }

    .footer {
        background-image: linear-gradient(to right, #A41916, #E42620);
        color: white;
        padding: 4%;
        border-radius: 10px;
        height: auto;
    }
    
    .title-prod{
        font-family: 'Poppins';
        font-size: 11vw;
        font-weight: 200;
        margin-bottom: 4%;
        color: #fff;
        text-align: center;
        
    }

    .footer {
        background-color: #d32f2f;
        color: white;
        padding: 20px;
        border-radius: 10px;
        text-align: center;
    }
    .footer ul {
        padding: 0;
        margin: 0;
        text-align: left;
        display: inline-block;
        line-height: 1; /* Ajusta el interlineado aquí */

    }
    .footer ul li {
        margin-bottom: 10px;
        margin-left: 5vw;
    }


    .address {
        margin: 0%;
    }
    .text-container {
        flex-grow: 1; /* Hace que este div ocupe el espacio restante */
    }
    .contact2 {
        display: flex;
        align-items: center;
    }

    .phone {
        display: flex;
        align-items: center;
    }
    
    .phone i {
        margin-right: 10px;
        margin-left: 1%;

    }
    
    .phone p {
        margin: 0; /* Eliminar márgenes predeterminados del párrafo */
    }
    
    .image-container {
        flex-shrink: 0; /* Evita que la imagen se encoja */
        margin-right: 10px; /* Espacio entre la imagen y el texto */
    }
    
    .CONTACT {
       margin-top: 10%;
    }

    .contact h3{
        font-size: 7vw;
        font-weight: 700;
    }

    .contact h4{
        font-size: 6vw;
        font-weight: 400;
    }

    .contact img{
        width: 7%;
        justify-content: center;
    }

    .up-arrow {
        display: absolute;
        top: -3%;
        left: 2vw;
        padding: 2%;
        width: 10%;
        height: 20%;
        background-image: linear-gradient(to right, #A41916, #E42620);
        color: white;
        border-radius: 15px;
        text-align: center;
        font-size: 8vw;
        font-weight: 200;
        text-decoration: none;
    }

    .rights,.privacy{
        text-align: center;
    }

    .privacy {
        position: relative; /* Necesario para posicionar las líneas */
        padding: 20px 0; /* Espacio alrededor del texto */
        margin-top: 30%;
    }
    
.privacy::before,
.privacy::after {
    content: ''; /* Contenido vacío para crear las líneas */
    display: block;
    height: .3px; /* Altura de las líneas */
    background-color: #000; /* Color de las líneas */
    position: absolute;
    left: 0;
    right: 0;
}

.privacy::before {
    top: 5%; /* Línea superior */
}

.privacy::after {
    bottom: 5%; /* Línea inferior */
}


.sub-text-footer-2{
    font-family: 'Poppins';
    font-weight: normal;
    font-size: 4.3vw;
    margin-bottom: 0%;
    text-align: left;
}

.footer-location {
    display: flex;
    align-items: center;
    gap: 1vw;
    font-size: 6vw;
}

.icon-phone-footer i {
    font-size: 6vw;

    
}
.no-style-link {
    text-decoration: none;
    color: inherit;
}

.phone-link {
    text-decoration: none;
    color: inherit;
    margin-bottom: 0%;
}

.footer-location i {
    margin-left: 1%; 
    
}

.line-behind {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: red;
    transform: translateY(-50%);
    z-index: 0; /* Line is behind the footer */
}

.container-fluid.products .footer {
    background-color: red;
    padding: 20px;
    position: relative;
    z-index: 1; /* Footer is above the line */
}

.fondo2 {
    position: fixed;
    z-index: -1;
    width: 200%;
    height: 5%;
    margin-left: -3%;
    top: 105vw; /* Adjusted to 'top' for consistency across devices */
}

.wider-image {
    width: 150% !important; /* Expand the image */
    max-width: none; /* Ensure it can expand beyond its container's width */
}

/* MENU MOVIL */

.icon-nav-space{
    margin-bottom: -3vw;
}

.offcanvas {
    background-color: black;
    color: white;
    width: 150vw;
    padding: 20px;
    
}


.offcanvas-header {
    padding: 0;
    margin-bottom: 20%;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-right:48%;
    margin-top:5%; 
     margin-bottom:15%;
}

.offcanvas-header .btn-close {
    filter: invert(1);
    color: white !important; 
}

.offcanvas-body ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.offcanvas-body ul li {
    margin: 10px 0;
    text-align: center;
}

.offcanvas-body ul li a {
    color: white;
    text-decoration: none;
    font-size: 18px;
    display: block;
    padding: 5px 0;
}


.social-icons {
    margin-top: 15%;
    text-align: center;
    gap: 20%;
}

.social-icons a {
    margin: 0 7%;
    color: white;
    font-size: 24px;
}


.social-icons:hover {
    color: #E42620;
}

.color-li:hover {
    color: #E42620;
}



.positionNavOriginal-m{
    position: fixed;
    top: 0%;
    left: 0;
    width: 100%;
    z-index: 1001;
    margin-top: -3vw;
    
    
}

.positionNavScroll{
    position: fixed;
    top: 17vw;
    left: 0;
    width: 100%;
    z-index: 1001;
   
}


.nav-symsa-m {
   
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top:-2%;
    padding-bottom: 3%;
    height: 28vw;
  }

  .white-m {
    background-color: white !important;
    transition: background-color 0.5s ease;
}

.img-logo-symsa{
    width: 15%;
    height: auto;
    position: relative;
    right: 27%;
    margin-top: -1%;
}

.logo-text-color-m{
    color: #ffffff;
    font-size:4vw;
    font-weight: bold;
}

.logo-text-color-scroll-m{
    color: #000000;
    font-size: 4vw;
    font-weight: bold;
}

.icons-nav{
    width: 10%;
    height: auto;
    position: relative;
    left: 28%;
    margin-top:7%;
    display: flex;
    flex-direction: column; /* Alinea los elementos en una columna */
    align-items: center; /* Centra los elementos horizontalmente */
    gap: 9vw;
    font-size: 4vw;
    padding-bottom: 2%;
}

.color-icon{
    color: #ffffff;
}

.color-icon-scroll{
    color: #4D4D4D;
}

.bannertoggler {
    position: center;
    margin-top: -2%;
    margin-left: -2%;
    border: none;
    background: none;
    border-style: none;
    z-index: 1;
    outline: none;
    box-shadow: none;
}

.bannertoggler-scroll {
    position: center;
    margin-top: -2%;
    margin-left: -2%;
    border: none;
    background: none;
    border-style: none;
    z-index: 1;
    outline: none;
    box-shadow: none;
}

.navbar-toggler {
    font-size: 5vw; 
    width: 15%;
    padding: 0;
    border: none;
    outline: none;
    box-shadow: none;
    }
    


    .navbar-toggler-icon {
    outline: none; 
    }

    .bannertoggler .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23FFFFFF' stroke-width='3' linecap='round' linejoin='round' d='M4 8h22M4 14h22M4 20h22'/%3E%3C/svg%3E");
        width: 10vw;
        height: 10vw;
    }

    .bannertoggler-scroll .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%234D4D4D' stroke-width='3' linecap='round' linejoin='round' d='M4 8h22M4 14h22M4 20h22'/%3E%3C/svg%3E");
        width: 10vw;
        height: 10vw;
    }

    .button-group3{
        align-items: center;
        justify-content: center;
        align-content: center;
        display:flex;
        
        flex-wrap: nowrap;
        
    }

    .button-link3 {
        margin-top: 5%;
        display: inline-flex;
        justify-content: center;
        text-align: center;
        width: 50%;
        margin-left: 20vw; 
        padding: 2% 9%; 
        border-radius: 50px;
        text-decoration: none;
        color: #fff;
        background-color: #000;
        transition: background-color 0.3s ease, color 0.3s ease;
        border: 1px solid #fff;
        font-size: 4vw; /* Usa vw para tamaño de fuente */
    }
    
    /* Icono dentro del botón */
    .button-link3 i {
        margin-left: 6vw; /* Usa vw para margen izquierdo del icono */
        transition: transform 0.3s ease;
        transform: rotate(45deg);
        font-size: 5vw;
    }
    
    /* Hover effects para el botón */
    .button-link3:hover {
        background-color: #E42620;
        color: #fff;
    }
    
    /* Hover effect para el icono */
    .button-link3:hover i {
        transform: translateX(0.5vw); /* Usa vw para transformar icono */
        transform: rotate(45deg);
    }

    .header-symsa{
        padding-top: 5%;
        padding-bottom: 6%;
        background-image: linear-gradient(to right, #A41916, #E42620);
    }
    
    .text-header{
        font-size: 4vw;
        color: #ffffff;
        font-family: 'Poppins';
        font-weight: normal;
    }

    .text-header img{
        width: 8%;
    }
    
    .container-head{
        padding-left: 0%;
        padding-right: 0%;   
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999; /* Ajusta según sea necesario para asegurarte de que el contenedor esté en la parte superior */
    }

    .positionStatic{
        position: fixed;
        top: 6%;
        left: 0;
        width: 100%;
        z-index: 1000;
        background-color: #ffffff !important;
        height:35vw;
    }

    .position-nosotros{
        margin-top:5%;
        
    }

    .color-li2:hover {
        color: #E42620;
    }
    
    .color-li2 {
        color: #000000;
    }

    .nosotros{
        padding: 0%;
        margin-bottom: 5vw;   
    }
    .title-nosotros{
        padding-top: 50%;
        font-weight: bold;
        font-size: 8vw;

    }

    .obj-title{
        font-weight: 300;
        color: #4D4D4D !important;
        font-size: 7vw;
    }
    .col-nosotros{
        font-family: 'Poppins', sans-serif;
         
    }
    .col-nosotros-flex{
        font-family: 'Poppins', sans-serif;
        display: flex;
        align-items: center; 
        padding: 0%;
        
    }
    .linea-nosotros-red {
        background: linear-gradient(to right, #363636, #000000);
        margin-right: 0.1vw;
    }
    .linea-nosotros-black {
        background: linear-gradient(to right, #A41916, #E42620);
        margin-left: 0.1vw;
    }
    .linea-nosotros-red, .linea-nosotros-black {
        height: 20%; 
        width: 50%; 
        margin-bottom: 2%;
        gap: 3%;
    }
    
    .col-nosotros-flex {
        flex-direction: row;
        width: 100%;
        padding: 0; 
        margin: 0; 
    }

   

    .col-nosotros-flex2 {
        flex-direction: column;
        align-items: flex-start; 
    }

    .icon-number {
        width: 4vw; 
        margin-right: 4%;
        margin-left: 4%;
    }

    .icon-number-2 {
        width: 14vw; 
        margin-right: 4%;
        margin-left: 2%;
    }

    .icon-number-3 {
        width: 13vw;
        margin-right: 3%;
        margin-left: 2%;
    }

    .icon-number-4 {
        width: 8vw; 
        margin-right: 3%;
        margin-left: 1%;
        margin-top: -3%;
    }

    .text-nosotros-col {
        font-size: 3.5vw;
        margin-right: 1%;
    }

    .historia {
        font-size: 7vw;
        font-family: 'Poppins', sans-serif;
        margin-top: 3%;

    }

   

    .text-nosotros-col.color-historia-text {
        color: #ffffff !important;
        text-align: left;
        margin-left: 1%;
        margin-top: 4%;
    }

    .padding-col {
        padding: 2.5%;
    }

    .size-col-nosotros {
        height: auto;
        width: 100%;
        padding: 0 5%; /* Añade padding a los lados para espacio interno */
    }

   

    .mision {
        display: flex;
        text-align: left !important;
        width: 100%;
    }

    .vision {
        display: flex;
        text-align: right !important;
        width: 100%;
    }

    .mision-t {
        font-size: 7vw;
        font-weight: 600;
        text-align: left;
    }

    .vision-t {
        font-size: 7vw;
        font-weight: 600;
        text-align: right;
    }

    .justify-text {
        text-align: justify;
    }
    .color-text-valores {
        text-align: center;
    }

    .valores  {
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: left;
    }

   
    
    .valores-title {
        color: #ffffff;
        font-size: 7vw;
        margin-bottom: -1vw;
        text-align: center; /* Centrar el título */
    }
    
    .icon-valores {
        width: 15%;
        height: auto;
        margin-bottom: 1vw;
        text-align: center;
        margin: 0 auto; /* Centrar el ícono */
    }
    
    .text-valores {
        color: #ffffff;
        font-size: 3.5vw;
        font-family: 'Poppins', sans-serif;
        margin: 5vw 0;
        width: 90%;
        text-align: center; /* Centrar el texto */
    }
    
    .valores-list {
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;
    }
    
    .valor-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 5vw;
    }
    
    
    .valor-item {
        align-items: center; /* Centrar todos los elementos dentro de valor-item */
    }
    
    .color-text-valores {
        color: #ffffff;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        font-size: 3.5vw; /* Ajuste del tamaño del texto para dispositivos móviles */
        margin: .5vw 0;
        width: 90%; /* Ajuste del ancho del texto para centrado y margen */
    }





    .position-container-contacto {
        display: block;
        margin-top: 50%;
        text-align: left;
    }

    .formulario-contacto {
        width: 90vw;
        margin: 0 auto;
        text-align: left;
    }

    .title-contacto{
        font-size:2vw;
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
        padding-right: 15%;
        padding-bottom: 4%;
    }

    .title-contacto-2{
        font-size: 4.5vw;
        font-family: 'Poppins', sans-serif;
        color: #4D4D4D;
        font-weight: normal;
        margin-right: 24%;
        margin-bottom: 3%;
    }

    .text-oblig{
        color: #A8A8A8;
        font-size: 3.5vw;
        text-align: right;
        right: 6%;
    }

    .text-contacto{
        color: #4D4D4D;
        font-size: 4vw;
        margin-bottom: 0%;
        
    }

    .input-contact {
        width: 80vw;
        height: 10vw;
        font-size: 3.5vw;
        margin-bottom: 5%;
        padding-left: 3%;
    }

    .input-contact.intup-comments {
        height: 30vw;
        padding-bottom: 2vw;
    }

    .btn-contact {
        padding: 4vw 5vw; /* Incrementa el tamaño del padding para botones más grandes */
        font-size: 6vw; /* Incrementa el tamaño de la fuente */
        border-radius: 1vw; /* Ajusta el borde redondeado según sea necesario */
        text-align: center;
        left: 45vw;
       
    }

    .title-contacto{
        font-size: 6vw;
    }


    .datos-contacto {
        padding: 5% 5%;
        text-align: left;
        font-size: 4vw;
    }

    .icon-contact {
        width: 5vw;
        margin: 0 auto;
        margin-left: -1vw;

    }

    .banner-contact-footer {
        margin-top: 10%;
    }

    .navbar, .offcanvas {
        font-size: 4vw;
    }

    .btn-nav-contact{
        width: 30vw;
        height: 10vw;
        font-size: 3vw !important;
    }

    .title-productos
 {
    color: #000;
    margin-top: 50vw; /* Ajusta este valor según sea necesario */
    font-size: 1.5rem; /* Tamaño de fuente ajustado */
    font-weight: bold; /* Negrita */
    margin-left: 2vw;
}

.sub-title-productos{
    font-size: 4vw; /* Tamaño de fuente ajustado */
    margin-left: 2vw;
}

    /* Ajustes generales del contenedor del carrusel */
    #productosCarousel {
        margin-top: 10vw; /* Ajusta este valor según sea necesario */
    }

/* Indicadores personalizados */
#productosCarousel .custom-indicators {
    position: absolute;
    bottom: 2vw;
    left: 50%;
    transform: translateX(-50%);
    z-index: 15;
}

#productosCarousel .custom-indicators button {
    width: 1vw;
    height: 1vw;
    border-radius: 50%;
    background-color: #fff;
    border: none;
    margin: 0 0.5vw;
}

#productosCarousel .custom-indicators button.active,
#productosCarousel .custom-indicators button:hover {
    background-color: #000;
}

/* Controles de navegación */
#productosCarousel .carousel-controls {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 50%; /* Center vertically */
    transform: translateY(-50%);
    padding: 0 2vw; /* Separate the arrows from the edges */
}

#productosCarousel .carousel-control-prev,
#productosCarousel .carousel-control-next {
    background: none;
    border: none;
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

#productosCarousel .carousel-control-prev-icon,
#productosCarousel .carousel-control-next-icon {
    width: 10vw; /* Increase size */
    height: 10vw; /* Increase size */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

#productosCarousel .carousel-control-prev-icon {
    background-image: url('data:image/svg+xml;utf8,<svg width="60" height="60" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 6L12 12L18 18" stroke="rgba(0,0,0,0.7)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 6L6 12L12 18" stroke="rgba(0,0,0,0.7)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
}

#productosCarousel .carousel-control-next-icon {
    background-image: url('data:image/svg+xml;utf8,<svg width="60" height="60" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 6L12 12L6 18" stroke="rgba(0,0,0,0.7)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 6L18 12L12 18" stroke="rgba(0,0,0,0.7)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
}

#productosCarousel .carousel-control-prev:hover .carousel-control-prev-icon,
#productosCarousel .carousel-control-next:hover .carousel-control-next-icon {
    transform: scale(1.2);
}

@keyframes arrow-bounce-prev {
    0%, 100% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(-5px);
    }
}

@keyframes arrow-bounce-next {
    0%, 100% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(5px);
    }
  }

#productosCarousel .carousel-control-prev-icon {
    animation: arrow-bounce-prev 1.5s infinite;
}

#productosCarousel .carousel-control-next-icon {
    animation: arrow-bounce-next 1.5s infinite;
}

#productosCarousel .text-carrousel-mobile-title {
    font-weight: bold;
}

#productosCarousel .button-link {
    width: auto;
    padding: 0.5vw 1vw;
    font-size: 3vw;
}

#mobile-slider {
    position: relative;
    overflow: hidden;
    height: 8vw; 
    margin-left: -3.5vw;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 2;
}

#mobile-slider .slide {
    position: absolute;
    width: 100%;
    transition: opacity 1s ease-in-out;
    opacity: 0;
}

#mobile-slider .slide.active {
    opacity: 1;
}


    




/*PRODUCTOS*/

.productos-detalles{
    margin-top: 50%;
    padding: 0%;    
    text-align: center;
}
.title-details {
    font-family: 'Poppins';
    font-size: 3.5vw;
    color: #000000;
    margin-bottom: 2%;
}

.title-details-products {
    font-family: 'Poppins';
    font-size: 5vw; 
    color: #000000;
    font-weight: bold;
}

.info-products {
    display: flex;
    flex-direction: column; 
    align-items: center;
    padding: 0%;
    width: 100%;
}

.img-product-details {
    flex: none;
    width: 80%;
    margin: 0 auto;
    padding: 0%;
}

.description-product {
    flex: none;
    width: 80%; 
    height: auto;
    margin: 2% 0;
    background-image: linear-gradient(to right, #000, #000);
    padding-top: 2%;
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 10%;
}



.text-details-products {
    font-size: 4vw; 
    font-family: 'Poppins';
    margin-bottom: 0%;
    color: #ffffff;
    text-align: justify;
}

.card-container {
    display: flex;
    flex-direction: row;
    overflow-x: scroll; 
    gap: 1%; 
}

.card-list-products {
    border-radius: 0%;
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-top: 5px solid;
    border-image: linear-gradient(to right, #A41916, #E42620) 1;
    width: 90%; 
    flex: none;
}

.card-list-products-2 {
    border-image: linear-gradient(to right, #363636, #000000) 1;
}

.title-details-2 {
    font-size: 5vw; 
    font-family: 'Poppins';
    color: #4D4D4D;
    margin-top: 2%;
    margin-bottom: 2%;
}

.text-list-products {
    font-size: 4vw; 
    font-family: 'Poppins';
    color: #4D4D4D;
    text-align: left;
}

.container-cards-products {
    padding: 0%;
}

.card-products-symsa {
    padding: 5%;
}

.btn-details-products {
    margin-top: 2%;
}

.menu-footer {
    background-color: #000;
}

.button-link-nav-details {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 2vw 5vw; 
    border-radius: 50px;
    text-decoration: none;
    color: #ffffff;
    background-image: linear-gradient(to right, #A41916, #E42620);
    transition: background-color 0.3s ease, color 0.3s ease;
    border: 0.1vw solid #000;
    font-size: 3vw; 
    margin-bottom: 3vw;
}

.col-details-products {
    padding-top: 3%;
    padding-left: 5%; 
}

.btn-details-products .btn-nav {
    width: 30vw; 
    max-width: 200px;
    font-size: .8em; 
    padding: 10px; 
    text-align: center;
    border-radius: 25px; 
}

.menu-footer .btn-group {
    display: flex;
    justify-content: space-around; 
    width: 100%;
    margin: 10px 0; 
}

.menu-footer .row {
    display: flex;
    justify-content: space-around;
}

.menu-footer .col-details-products:nth-child(1) {
    order: 1; 
}

.menu-footer .col-details-products:nth-child(2) {
    order: 3; 
}

.menu-footer .col-details-products:nth-child(3) {
    order: 2;
}

.dowload-catalogo{
    height: 4vw;
    width: 20vw;
    left:0;
    display: inline-flex;
    align-items: center;
    text-align: center;
    position:relative;
    left: -34vw;
    
}
.size-icon-dowload{
    height: auto;
    width: 4vw;
    display: inline-flex;
}
.text-catalogo{
    color: #ffffff;
    font-family: 'Poppins';
    font-size: 3vw;
    display: inline-flex;
    padding-top: 4vw ;
}

.cards-wrapper {
    display: flex;
    flex-wrap: wrap;
    max-height: none;
    overflow-y: visible;
    justify-content: space-between;
}

.card-list-products {
    flex: 1 1 calc(100% - 41vw); /* Full width on mobile */
    margin-bottom: 30px; /* Space between cards */
    padding: 10px; /* Add padding inside the card */
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.text-list-products {
    font-size: 2.7vw; /* Adjust font size for readability */
    line-height: 4vw; /* Adjust line height */
    padding-bottom: 5vw;
    padding-top: 1vw;
    word-wrap: break-word; /* Allow long words to break and wrap to the next line */

}

.body-card-productos-background{
    background-image: linear-gradient(to right, #ffffff, #ffffff);
    
}
.body-card-productos-background-2{
    background-image: linear-gradient(to right, #F2F2F2, #F2F2F2);
}

}

.card {
    text-decoration: none;
    color: inherit;
}



.card img {
    display: block;
    width: 100%;
}


.card-link {
    display: block;
    text-decoration: none;
    color: inherit;
}


